.form-group{
    margin-bottom: 15px;
}
.Toastify__toast-body div{
    font-weight: 500 !important;
    color: #000;
}
th div:nth-child(2){
    display: none;
}
th:nth-child(1),th:nth-child(2),th:nth-child(6),th:nth-child(7),th:nth-child(8),th:nth-child(9),th:nth-child(10),
td:nth-child(1),td:nth-child(2),td:nth-child(6),td:nth-child(7),td:nth-child(8),td:nth-child(9),td:nth-child(10)
{
    text-align:center;
}
td:nth-child(9){
    vertical-align: middle !important;
}
td .form-switch input{
    cursor: pointer;
}
td .btn{
    margin: 0 5px;
}
.table> :not(caption)>*>* {
    vertical-align: baseline;
}
.tableAvatarImg{
    border-radius: 50px;
}
.dz-message {
    text-align: center;
    padding: 15px;
}
.ck-editor{
    border: 1px solid hsl(0, 0%, 80%) !important;
    border-radius: 4px !important;
}
.rdw-dropdown-selectedtext {
    color: #000;
}
.ck-editor__main{
    max-height: 245px;
    overflow: auto;
}
.accImg{
    height: 175px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #eff2f7;
    color: #fff;
    text-align: right;
    padding: 10px;
    vertical-align: bottom;
    display: flow-root;        
}
.accImg i{
    cursor: pointer;
}    
.viewpass{
        display: inline-block;
        position: absolute;
        top: 17px;
        right: 11px;
        font-size: 18px;
        opacity: 0.6;
        cursor: pointer;
    }
.viewpass:hover {
    opacity: 0.9;
}
#password{
    padding-right: 50px;
}
.react-table .btn {
    padding: 0.15rem 0.35rem;
}
.option-list-title{
    font-weight: 600;
    margin-top: 15px;
}
.text-right {
    text-align: right !important;
}
.text-left {
    text-align: left !important;
}
.ctext-wrap{
    width: 100%;
}
.form-switch .form-check-input{
    cursor:pointer;

}
